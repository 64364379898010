/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { getAssetPath as t, setAssetPath as o } from "@esri/calcite-components/dist/components/index.js";
import { getAssetUrl as s } from "../../assets.js";
import "../../core/has.js";
import { makeAbsolute as e } from "../../core/urlUtils.js";
let c;
function n() {
  try {
    t(".");
  } catch {
    o(e(s(c)));
  }
}
function r(t) {
  const o = [];
  for (const s of Object.keys(t)) customElements.get(`calcite-${s}`) || o.push(t[s]?.());
  return o.length > 0 ? Promise.all(o) : null;
}
c = "components/assets";
export { n as commitAssetPath, r as loadCalciteComponents };