/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { dom as e } from "../maquette/dom.js";
import "../maquette/projector.js";
import { defaultAdvancedProjectorOptions as t } from "./advanced-projector-options.js";
import { applyDefaultProjectionOptions as r } from "./utils.js";
const o = (e, t) => {
    const r = [];
    for (; e && e !== t;) r.push(e), e = e.parentNode;
    return r;
  },
  n = (e, t) => e.find(t),
  d = (e, t, r = !1) => {
    let o = e;
    return t.forEach((e, d) => {
      const s = o?.children ? n(o.children, t => t.domNode === e) : void 0;
      r && !s && d !== t.length - 1 || (o = s);
    }), o;
  },
  s = n => {
    let s;
    const i = {
        ...t,
        ...n
      },
      c = r(i),
      a = c.performanceLogger;
    let m,
      p = !0,
      l = !1;
    const f = [],
      u = [],
      h = (e, t, r) => {
        let n;
        c.eventHandlerInterceptor = (e, t, r, c) => function (e) {
          let t;
          a("domEvent", e);
          const r = o(e.currentTarget, n.domNode),
            c = r.some(e => customElements.get(e?.tagName?.toLowerCase()));
          if (e.eventPhase === Event.CAPTURING_PHASE || !c) r.reverse(), t = d(n.getLastRender(), r);else {
            const r = e.composedPath(),
              o = r.slice(r.indexOf(e.currentTarget), r.indexOf(n.domNode)).reverse();
            t = d(n.getLastRender(), o, !0);
          }
          let m;
          return t && (m = i.handleInterceptedEvent(s, t, this, e)), a("domEventProcessed", e), m;
        }, i.postProcessProjectionOptions?.(c);
        const m = r();
        n = e(t, m, c), c.eventHandlerInterceptor = void 0, f.push(n), u.push(r), i.afterFirstVNodeRendered && i.afterFirstVNodeRendered(n, m);
      };
    let v = () => {
      if (m = void 0, p) {
        p = !1, a("renderStart", void 0);
        for (let e = 0; e < f.length; e++) {
          const t = u[e]();
          a("rendered", void 0), f[e].update(t), a("patched", void 0);
        }
        a("renderDone", void 0), p = !0;
      }
    };
    return i.modifyDoRenderImplementation && (v = i.modifyDoRenderImplementation(v, f, u)), s = {
      renderNow: v,
      scheduleRender: () => {
        m || l || (m = requestAnimationFrame(v));
      },
      stop: () => {
        m && (cancelAnimationFrame(m), m = void 0), l = !0;
      },
      resume: () => {
        l = !1, p = !0, s.scheduleRender();
      },
      append: (t, r) => {
        h(e.append, t, r);
      },
      insertBefore: (t, r) => {
        h(e.insertBefore, t, r);
      },
      merge: (t, r) => {
        h(e.merge, t, r);
      },
      replace: (t, r) => {
        h(e.replace, t, r);
      },
      detach: e => {
        for (let t = 0; t < u.length; t++) if (u[t] === e) return u.splice(t, 1), f.splice(t, 1)[0];
        throw new Error("renderFunction was not found");
      }
    }, s;
  };
export { s as createAdvancedProjector };