/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
function e(e) {
  return "string" == typeof e ? document.getElementById(e) : e ?? null;
}
function n(e) {
  for (; e.hasChildNodes();) e.removeChild(e.firstChild);
}
function t(e, n) {
  const t = n.parentNode;
  t && (t.lastChild === n ? t.appendChild(e) : t.insertBefore(e, n.nextSibling));
}
function o(e, n) {
  const t = n.parentNode;
  t && t.insertBefore(e, n);
}
function i(e, n) {
  for (;;) {
    const t = e.firstChild;
    if (!t) break;
    n.appendChild(t);
  }
}
function r(e) {
  e.parentNode && e.parentNode.removeChild(e);
}
const l = "function" == typeof Element.prototype.closest ? (e, n) => e.closest(n) : (e, n) => {
  let t = e;
  do {
    if (t.matches(n)) return t;
    t = t.parentElement;
  } while (null !== t && 1 === t.nodeType);
  return null;
};
export { e as byId, l as closest, n as empty, t as insertAfter, o as insertBefore, r as remove, i as reparent };