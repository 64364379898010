/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
function e(e) {
  return (s, r) => {
    s.hasOwnProperty("_messageBundleProps") || (s._messageBundleProps = s._messageBundleProps ? s._messageBundleProps.slice() : []);
    s._messageBundleProps.push({
      bundlePath: e,
      propertyName: r
    });
  };
}
export { e as messageBundle };